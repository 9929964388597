import React from "react"
import "../styles/global.css"
import Layout from "../components/Layout/Layout"
import {makeStyles} from "@material-ui/styles"

const useStyles = makeStyles({
    root: {
        backgroundColor: "red",
        flexGrow: "1",
    },
});

export default function Projects() {
    const classes = useStyles();
    return (
        <Layout>
            <p className={classes.root}>Projects</p>
        </Layout>
    )
}
